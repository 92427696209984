import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authorized, fetchUser } from '../../store/features/user/userSlice';
import useTheme from '../../store/features/partner/hooks/useTheme';
import usePartnerIcon from '../../store/features/partner/hooks/usePartnerIcon';
import { AppDispatch, RootState } from '../../store/store';
import { loadPartner } from '../../store/features/partner/partnerSlice';
import usePartnerLoginToken from '../../store/features/user/hooks/usePartnerLoginToken';
import { UserData } from '../../store/features/user/types';

interface PreloadProps {
  children: React.ReactNode;
}

function PreloadWrapper({ children }: PreloadProps) {
  const [isReady, setIsReady] = useState(false);
  const partner = useSelector((state: RootState) => state.partner.data);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    Promise.all([
      authorized() ? dispatch(fetchUser()) : Promise.resolve({ payload: null }),
      dispatch(loadPartner({ id: null })),
    ]).then(([{ payload: userData }, { payload: partnerData }]) => {
      if (!partnerData) {
        const accounts = (userData as UserData)?.accounts;
        return accounts?.[0]?.partner
          ? dispatch(loadPartner({ id: accounts[0]?.partner?.id }))
          : null;
      }
      return null;
    }).then(() => setIsReady(true));
  }, [dispatch]);

  useTheme(partner?.theme);
  usePartnerIcon(partner?.logoFavicon);
  usePartnerLoginToken();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isReady ? <>{children}</> : null;
}

export default PreloadWrapper;
