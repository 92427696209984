interface ThemeObject {
  [key: string]: string;
}

const useTheme = (theme?: string) => {
  if (!theme || document.querySelector('#switchboard-partner-theme-style')) return;

  const themeStyle = document.createElement('style');
  themeStyle.id = 'switchboard-partner-theme-style';
  document.head.appendChild(themeStyle);

  themeStyle.innerHTML = `${theme}`;
};

export default useTheme;
